import { pathToRegexp } from "path-to-regexp";
import { NTSE_COOKIE_NAME } from "./constants";
import { isNotNullOrUndefined } from "@telia/b2b-utils";

/**
 * Creates and sets TSE (mybusiness-fe) browser cookie.
 */
export const setTseCookie = (): void => {
  document.cookie = getTseCookieHeader();
};

/**
 * Creates and sets NTSE browser cookie.
 */
export const setNtseCookie = (): void => {
  document.cookie = getNtseCookieHeader();
};

/**
 * Invalidates/deletes NTSE browser cookie.
 */
export const invalidateNtseCookie = (): void => {
  document.cookie = getNtseInvalidateCookieHeader();
};

/**
 * Returns a TSE (mybusiness-fe) cookie header string.
 */
export const getTseCookieHeader = (): string => {
  return createSetCookieHeader(NTSE_COOKIE_NAME, "0", 10, "path=/;", "secure;", "SameSite=Lax;");
};

/**
 * Returns an NTSE cookie header string.
 */
export const getNtseCookieHeader = (): string => {
  return createSetCookieHeader(NTSE_COOKIE_NAME, "1", 10, "path=/;", "secure;", "SameSite=Lax;");
};

/**
 * Returns an invalidate NTSE cookie header string.
 */
export const getNtseInvalidateCookieHeader = (): string => {
  return createSetCookieHeader(NTSE_COOKIE_NAME, "", -1, "path=/;", "secure;", "SameSite=Lax;");
};

/**
 * Creates a string to be used in a set-cookie header. See usages for examples.
 * This method can be used for both setting and invalidating a cookie.
 *
 * @param cookieName
 * @param cookieValue Value or e.g. '' if invalidate cookie
 * @param daysUntilExpiration Value > 0 expiration in future, value < 0 invalidate cookie etc...
 * @param headerDirectives Additional directives to set with cookie - e.g.: path, domain, SameSite, secure etc...
 */
export const createSetCookieHeader = (
  cookieName: string,
  cookieValue: string,
  daysUntilExpiration?: number,
  ...headerDirectives: string[]
): string => {
  let cookieHeader = `${cookieName}=${encodeURIComponent(cookieValue)};`;

  if (isNotNullOrUndefined(daysUntilExpiration)) {
    // daysUntilExpiration < 0 means invalidate cookie
    const expirationDate = getNewDate();
    expirationDate.setDate(expirationDate.getDate() + daysUntilExpiration!);
    cookieHeader += ` expires=${expirationDate.toUTCString()};`;
  }

  for (const hd of headerDirectives) {
    cookieHeader += ` ${hd}` + (hd.indexOf(";") < 0 ? ";" : ""); // append ; if not present...
  }

  return cookieHeader;
};

export function getCookieValue(cookieName: string): string | null {
  const match = document.cookie.match(new RegExp("(^| )" + cookieName + "=([^;]+)"));

  return match && match[2] !== undefined ? decodeURIComponent(match[2]) : null;
}

export const getNewDate = () => {
  return new Date();
};

export const addNtseUrls = (urls: Array<string>): Array<RegExp> =>
  urls.map((url) => pathToRegexp(url));

export const isUrlInNtse = (ntseUrlRegexes: Array<RegExp>, pathname: string): boolean =>
  ntseUrlRegexes.some((urlRegex) => urlRegex.test(pathname));

export const reloadBrowser = (pathname?: string): void => {
  if (pathname) {
    location.href = pathname;
  } else {
    location.reload();
  }
};

export const getCurrentHost = (): string => window.location.host;

export function isForcingNtse() {
  const ntseCookieValue = getCookieValue(NTSE_COOKIE_NAME);
  return ntseCookieValue === "1-lock";
}

export const isOnCots = (hostname: string): boolean =>
  hostname ? hostname.includes("cots.") : false;
