import {
  COTS_PROD_DOMAINS,
  COTS_TEST_DOMAINS,
  TELIA_SE_PROD_DOMAINS,
  TELIA_SE_TEST_DOMAINS,
} from "@telia/b2x-telia-se-domain-names";
import { isTestEnv } from "@telia/b2b-utils";

export const getNtseUrls = (): string[] => {
  // Active NTSE production URLs
  const prodUrls = [
    "/foretag/mybusiness/federatedsso",

    // skaffa-mybusiness is also covered by the nonScopeId rule - included for completeness
    "/foretag/mybusiness/skaffa-mybusiness",
    "/foretag/mybusiness/skaffa-mybusiness/inte-inloggad",
    "/foretag/mybusiness/skaffa-mybusiness/inloggad",

    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/release-notes",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/fakturor/fakturor",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/fakturor/hantera-fakturering(.*)",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/fakturor/dokument",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/bestall/formaner",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/bestall/produkter-tjanster",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/bestall/alla-bestallningar",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/bestall/lagda-bestallningar",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/bestall/oppna-mobilt-abonnemang/:optionalRemainingUrl(.*)?",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/hantera/oppna-mobilt-abonnemang/:optionalRemainingUrl(.*)?",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/bestall/oppna-iot-abonnemang/:optionalRemainingUrl(.*)?",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/bestall/touchpoint-plus-nybestallning",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/bestall/iot-sim-kort",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/bestall/touchpoint-plus-eftermarknad/:optionalRemainingUrl(.*)?",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/bestall/datanet/:optionalRemainingUrl(.*)?",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/bestall/fastighetsagare/hardvara",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/support/arenden",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/support/arenden/(skapa|fraga)/:subscriptionId(\\w+)?",
    "/foretag/mybusiness/:scopeId(\\d+)/support/arenden/detaljer/:caseId(\\w+)?",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/support/kontakt",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/support/driftstatus",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/support/rapporter/(tillganglighet-av-tjanster|natverkstillganglighet|sla-rapporter)",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/hantera/produkter-tjanster/mobiltelefoni/:productId",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/hantera/produkter-tjanster/mobiltbredband/:productId",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/bestall/m365",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/hantera/produkter-tjanster/:productCategory/ny/:productId/:optionalRemainingUrl(.*)?",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/hantera/produkter-tjanster",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/hantera/produkter-tjanster/telefoni",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/hantera/produkter-tjanster/bredband",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/hantera/produkter-tjanster/vaxel",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/hantera/produkter-tjanster/ovrigt",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/hantera/produkter-tjanster/sok",
    "/foretag/mybusiness/:scopeId(\\d+)/hantera/produkter-tjanster/datanet-vpn/leveranser",
    "/foretag/mybusiness/:scopeId(\\d+)/hantera/produkter-tjanster/datanet-vpn/leveranser/:deliveryId(.+)",
    "/foretag/mybusiness/:scopeId(\\d+)/hantera/produkter-tjanster/datanet-vpn/vpn-administration",
    "/foretag/mybusiness/:scopeId(\\d+)/hantera/anvandare((?!/bjudin|/editera/\\d+))",
    "/foretag/mybusiness/:scopeId(\\d+)/hantera/anvandare/bjudin",
    "/foretag/mybusiness/:scopeId(\\d+)/hantera/anvandare/editera/:accessProfileId(\\d+)",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/hantera/rapporter",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/bestall/:tscid(\\d+)/anpassa/:configurationid([\\d\\-]+)",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/bestall/:tscid(\\d+)/kassa/:basketId([\\d\\-+]+)",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/bestall/:tscid(\\d+)/bekraftelse/:orderId([\\d\\-+]+)",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/installningar/foretag",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/installningar/minprofil(.*)",
    "/foretag/mybusiness/no-access(.*)$",
    "/foretag/mybusiness/page-not-found(.*)$",
    "/foretag/mybusiness/something-went-wrong(.*)$",
    "/foretag/mybusiness/:scopeId(\\d+)/:orgNr(\\d+)?/not-authorized",
    "/foretag/mybusiness",
    "/foretag/mybusiness/:scopeId(\\d+)/sso/surfblaster-terminal",
    "/foretag/mybusiness/:scopeId(\\d+)/minmobil",
    "/foretag/mybusiness/minmobil",
    "/foretag/mybusiness/:scopeId(\\d+)/hantera/produkter-tjanster/datanet-vpn/notifieringar",
    "/foretag/mybusiness/:scopeId(\\d+)/hantera/produkter-tjanster/datanet-vpn/notifieringar/:personId(\\d+)",
    "/foretag/mybusiness/:scopeId(\\d+)/hantera/produkter-tjanster/datanet-vpn/notifieringar/:personId(\\d+)/ny-overvakning",
    "/foretag/mybusiness/:scopeId(\\d+)/hantera/produkter-tjanster/datanet-vpn/notifieringar/:personId(\\d+)/:notificationId(\\d+)",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/new/mobilabonnemang",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/new/mobiltelefoner",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/new/tillbehor",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/new/bredband",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/new/mobilabonnemang/produkt/:productCode",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/new/mobiltelefoner/produkt/:productCode",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/new/tillbehor/produkt/:productCode",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/mobilabonnemang",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/mobiltelefoner",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/tillbehor",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/bredband",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/emn",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/mobilabonnemang/produkt/:productCode",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/mobiltelefoner/produkt/:productCode",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/tillbehor/produkt/:productCode",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/emn/produkt/:productCode",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/checka-ut",
    "/foretag/mybusiness/:scopeId(\\d+)/bestall/:orgNr(\\d+)?/din-bestallning/:basketId([A-Za-z0-9]+)",
    "/foretag/mybusiness/:scopeId(\\d+)/sok",
    "/foretag/mybusiness/inbjudan/:inviteId(\\d+)/:nonce(.+)",
    "/foretag/mybusiness/inbjudan/inloggad/:inviteId(\\d+)/:nonce(.+)",
    "/foretag/mybusiness/inbjudan/inte-inloggad/:inviteId(\\d+)/:nonce(.+)",
    "/foretag/mybusiness/om/villkor-mybusiness",
    "/foretag/mybusiness/:nonScopeId((?!telia-admin|minmobil|logout|overlay|verifieraepostadress|getLastAccessTime|acceptInvite|begar-nytt-losenord-old|begar-nytt-losenord|Varfor-MB|varfor-mb-engelska|om|webbinarier|superuser-intro|superuser-guide|mobil-administration|komplettera|SRA|api|ajax|sso_sevone_error|sso_error|glomt-anvandarnamn|tpadmin-register|teliaFinanceRedirect|begar-nytt-losenord-datakom|inbjudan|feedback|register-new|registrera-new|register|[o]?\\d+).+)/(.*)?",
  ];

  const prodUrlsAppend = []; // Noted that we need both to remove and add url's sometimes for the test env.

  // Active NTSE test URLs
  const testUrls = [
    "/foretag/mybusiness/:scopeId(\\d+)/hantera/overvakning-trafik",
  ];
  return isTestEnv() ? prodUrls.concat(testUrls) : prodUrls.concat(prodUrlsAppend);
};

export const DEV_FORCE_NTSE_COOKIE_VALUE = "1-lock";
export const DEV_FORCE_TSE_COOKIE_VALUE = "0-lock";
export const NTSE_COOKIE_NAME = "telia-canary";
const _TEST_TRIGGER_HOSTS = [...TELIA_SE_TEST_DOMAINS, ...COTS_TEST_DOMAINS];

export const TRIGGER_HOSTS = [...TELIA_SE_PROD_DOMAINS, ...COTS_PROD_DOMAINS].concat(
  isTestEnv() ? _TEST_TRIGGER_HOSTS : []
);
